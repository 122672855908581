import React from "react"
import { Link } from "gatsby"
import benutzermanagementHeader from "../../../assets/images/products/netversys/benutzermanagement_header.svg"
import wmBenutzermanagement from "../../../assets/images/products/netversys/wm-plugins.svg"
import Sidebar from "./Sidebar"
import { Col, Container, Row } from "react-bootstrap"
import WichtigeMerkmale from "../WichtigeMerkmale"
import LexikonBadgesRandomSection from "../../lexikon/LexikonBadgesRandomSection"
import { StaticImage } from "gatsby-plugin-image"

const Benutzermanagement = () => {

  return (
    <>
      <section className="services-details-area ptb-100">
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-image">
                <img src={benutzermanagementHeader} alt="Benutzermanagement" />
              </div>

              <div className="services-details-desc">
                <span className="sub-title">NETVERSYS</span>
                <h1 className={"pt-3"}>Benutzer und Rechte</h1>
                <p>
                Benutzer sind in NETVERSYS integraler Bestandteil von NETVERSYS und mit diesen auch das Rechte- und
                  Rollensystem, welches eine sehr granulare Steuerung von Berechtigungen bietet. Ein Benutzer kann
                  hierbei sowohl ein Versandsteuerungsmitarbeiter sein, als auch ein Mitarbeiter am Versandplatz. In
                  NETVERSYS können hierbei beliebig viele Nutzer angelegt werden. Anders als z.B.
                  bei der <Link to={"/versandsoftware/netversys/hardware/"}>Hardware</Link>, sind
                  Benutzer in NETVERSYS global und werden unabhängig von der aktuellen Mandantenauswahl angelegt. Die
                  Zuordnung welche Mandanten der angelegte Benutzer bedienen kann, sowie welche Rollen und Rechte er
                  besitzt, wird bei der Editierung der Eigenschaften eines Benutzers vorgenommen.
                </p>

                <WichtigeMerkmale items={[
                  "Nummernkreise pro Mandant möglich",
                  "Rechte und Rollen pro Mandant",
                  "einfache Übersicht",
                  "Konzernstrukturen abbildbar",
                ]} image={<img src={wmBenutzermanagement}
                               alt="Benutzermanagement Merkmale"
                               className={"ml-lg-4 ml-0"} />} />

                <h3>Relevantes aus dem Lexikon</h3>

               <LexikonBadgesRandomSection/>

              </div>
            </Col>

            <Col lg={4} md={12}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="services-area pt-4 pb-70 bg-f1f8fb">
        <Container>
          <span className="mb-2 text-primary"><i className={"bx bxs-bullseye"} /> Im Detail</span>
          <h2>Benutzermanagement</h2>
          <Row className={"mt-5"}>
            <Col md={6} xs={12}>
              <StaticImage
                src="../../../assets/images/products/netversys/screenshots/nv_users_framed.png"
                alt="Benutzermanagement Screenshot"
                placeholder="blurred"
                width={600}
              />
            </Col>
            <Col md={{ offset: 1, span: 5 }} xs={{ offset: 1, span: 11 }}>
              <p>
                Um den Gegebenheiten in einem Lager und den verschiedenen Devices am Versandplatz gerecht zu werden,
                kann NETVERSYS für jeden Benutzer einen Barcodelogin erstellen. Dieser Barcodelogin erlaubt das
                Einloggen über einen eingescannten Barcode. Der NETVERSYS Login-Bildschirm besteht beim Barcodelogin nur
                aus einem Textfeld, welcher den Barcode entgegennimmt. Der Barcode mit dem Namen des Benutzers und
                seinem Passwort wird hierbei beim Mitarbeiter verwahrt und erlaubt ein schnelles einloggen ohne Tastatur
                oder Maus.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="services-area pt-4 pb-70 bg-fafafb">
        <Container>
          <span className="mb-2 text-primary"><i className={"bx bxs-star"} /> Feature Highlight</span>
          <h2>Rollen und Rechte</h2>
          <Row className={"mt-5"}>
            <Col md={{ span: 5 }} xs={{ span: 12 }}>
              <p>
                Neben der Zuordnung von Rechte und Rollen bei der Anlage eines Benutzers ist die Verwaltung von Rollen
                und das leichte Analysieren von Rechteverteilungen ein wichtiger Bestandteil von benutzerbasieren
                Softwaresystemen. NETVERSYS hat ein einfach zu bedienendes Rollenmanagement integriert. Hierbei können
                beliebige Rollen angelegt und mit Rechten versehen werden. Diese Rollen können dann im Anschluss an
                Benutzer verteilt werden. Das Besondere hierbei ist, dass ein Nutzer neben einzelnen Rollen auch
                explizite Rechte haben kann. Somit ist ein Mischbetrieb von Rollen und Rechten jederzeit möglich. In der
                Rechteverwaltung kann man darüber hinaus einfach sehen, in welchen Rollen welche bestimmte Rechte
                zusamengefasst sind und welche Nutzer eine Direktzuweisung haben.
              </p>
            </Col>
            <Col md={{ span: 6, offset: 1 }} xs={12}>
              <StaticImage
                src="../../../assets/images/products/netversys/screenshots/nv_user_rights_framed.png"
                alt="Rollen und Rechte Screenshot"
                placeholder="blurred"
                width={600}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Benutzermanagement
