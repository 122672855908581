import React from "react"
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import Footer from "../../../components/App/Footer"
import Benutzermanagement from "../../../components/ProductDetails/netversys/Benutzermanagement"
import Seo from "../../../components/App/Seo"

const Benutzer = () => {
  return (
    <Layout>
      <Seo title={"Moderne Benutzermanagement mit Rollen und Rechten."}
           description={"Benutzer sind integraler Bestandteil von " +
           "NETVERSYS. Das Rechte- und Rollensystem bietet " +
           "eine sehr granulare Steuerung von Berechtigungen."}
           image={"/images/og/netversys-main-og.png"}>
            <script type="application/ld+json">{`
                      {
                        "@context": "https://schema.org",
                        "@type": "BreadcrumbList",
                        "itemListElement": [{
                          "@type": "ListItem",
                          "position": 1,
                          "name": "Home",
                          "item": "https://logentis.de"
                        },{
                          "@type": "ListItem",
                          "position": 2,
                          "name": "Versandsoftware",
                          "item": "https://logentis.de/versandsoftware/"
                        },{
                          "@type": "ListItem",
                          "position": 3,
                          "name": "Benutzermanagement",
                          "item": "https://logentis.de/versandsoftware/netversys/benutzer/"
                        }]
                      }
                      `}
            </script>
      </Seo>
      <Navbar />
      <Benutzermanagement />
      <Footer />
    </Layout>
  )
}

export default Benutzer
